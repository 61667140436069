// Styles
// CoreUI Icons Set
@import '~@coreui/icons/css/all.min.css';
// Import Flag Icons Set
@import '~flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
@import '~font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
@import '~simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
@import './scss/style.scss';

div.stickyActionBarNormal {
  z-index: 1000;
  width: calc(100% - 200px);
  min-width: 600px;
  height: 100px;
}

div.stickyActionBarHidden {
  z-index: 1000;
  width: calc(100% - 200px);
  min-width: 600px;
  height: 0;
  visibility: hidden;
}

div.stickyActionBar {
  z-index: 1000;
  width: calc(100% - 200px);
  min-width: 600px;
  height: 100px;
  margin-top: 80px;
}

@import '~@odc/od-react-belt/lib/styles/compiled.css';
