// Here you can add other styles

main {
  background-color: #f2f2f2; // ODColors.ContentAreaBackground
}

.max-200-w {
  max-width: 200px;
}

.width-200 {
  width: 200px;
}

.width-100 {
  width: 100px;
}

td.vcenter {
  vertical-align: middle;
}
