/* Customize the label (the.dc-container) */
.dc-container {
  display: block;
  position: relative;
  padding-left: 23px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.dc-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.dc-checkmark {
  position: absolute;
  top: -2px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.dc-container:hover input ~ .dc-checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.dc-container input:checked ~ .dc-checkmark {
  border-radius: 3px;
  background-color: #29b6ca;
  border: 0;
}

/* Create the.dc-checkmark/indicator (hidden when not checked) */
.dc-checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the.dc-checkmark when checked */
.dc-container input:checked ~ .dc-checkmark:after {
  display: block;
}

/* Style the.dc-checkmark/indicator */
.dc-container .dc-checkmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.dc-container .dc-checkmark.intermediate:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.dc-container .dc-checkmark.intermediate {
  content: '-';
}
